import React, { ReactNode } from 'react';
import { Button, Input, InputNumber } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

export interface IQtyInput {
  value: number;
  onIncrease?: () => void;
  onDecrease?: () => void;
  label?: string;
  size?: 'large' | 'small';
  min?: number;
  max?: number;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  hideIconLeft?: boolean;
  hideIconRight?: boolean;
  disableIncrease?: boolean;
  disableDecrease?: boolean;
  allowInput?: boolean;
  onChange?: (value: any) => void;
}

const QtyInput = ({
  value,
  onIncrease = () => {},
  onDecrease = () => {},
  label,
  size = 'small',
  min = 0,
  max,
  iconLeft = <MinusOutlined />,
  iconRight = <PlusOutlined />,
  hideIconLeft = false,
  hideIconRight = false,
  disableDecrease = false,
  disableIncrease = false,
  allowInput = false,
  onChange = () => {},
}: IQtyInput) => {
  const isDisabledDecrease = () => {
    if (disableDecrease) return true;
    if (min !== 0 && value <= min) return true;

    return false;
  };

  const isDisableIncrease = () => {
    if (disableIncrease) return true;
    if (max) { 
      return value >= max;
    }

    return false;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    const allowNegatives = min < 0;
    
    const regex = allowNegatives ? /^-?\d*(\.\d*)?$/ : /^(?!-)-?\d*(\.\d*)?$/;
    
    if (regex.test(inputValue) || (inputValue === '-' && allowNegatives) || inputValue === '') {
      onChange(inputValue);
    }
  };

  return (
    <>
      <div
        className={
          size === 'small' ? 'qty-small-container' : 'qty-large-container'
        }
      >
        <div className='qty-button-container'>
          {!hideIconLeft && (
            <Button
              size={size}
              type='text'
              className='qty-button'
              icon={iconLeft}
              disabled={isDisabledDecrease()}
              onClick={() => onDecrease()}
            />
          )}
        </div>
        <div className='qty-value'>
          {allowInput ? (
            <Input 
              min={0} 
              value={value} 
              onChange={handleChange}
            />
          ) : (value)}
        </div>
        <div className='qty-button-container'>
          {!hideIconRight && (
            <Button
              size={size}
              type='text'
              className='qty-button'
              icon={iconRight}
              disabled={isDisableIncrease()}
              onClick={() => onIncrease()}
            />
          )}
        </div>
      </div>
      {label && (
        <div
          className={size === 'small' ? 'qty-small-label' : 'qty-large-label'}
        >
          {label}
        </div>
      )}
    </>
  );
};

export default QtyInput;
